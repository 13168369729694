import Vue from 'vue';
import moment from 'moment';
import { Module } from 'vuex';
import { Post, RootState, PostsState, Comment, User, PostParameters, PostCreationPayload, Investigation } from '@/types';
import { API_HOST } from '@/config';
import { createBaseStore, VuexActionResult } from '@/store/util';
import { aggregatePostData, createQueryParams } from '@/util.posts';
import { trackCommentCreation, trackPostCreation } from '@/tracking';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

const url = '/posts';
const { store, mutations } = createBaseStore('posts', url);

let lastPostData = null;
try {
    lastPostData = JSON.parse(localStorage.lastPostData);
} catch(err) {
    // Pass
}

const postsStore: Module<PostsState, RootState> = {
    state: {
        ...store.state,
        byUniqueId: {},
        queryParams: {
            // location keys with params as values
            // i.e 'global': { distance: 20 }
            home: {},
        },
        mostRecent: [],
        selectedPost: null,
        lastPostData,
    },
    getters: {
        latestPost(state) {
            return state.mostRecent.find(post => {
                const hasPhotos = post.photoObjs.length > 0;
                const isTesting = String(post.textBody).toUpperCase().trim() === 'TESTING';
                return hasPhotos && !isTesting;
            });
        },
        lastPostData(state) {
            return state.lastPostData;
        }
    },
    mutations: {
        ...store.mutations,
        POSTS_SET_ITEMS_WITH_ID(state, payload) {
            const { items, pagination, params } = payload;
            Vue.set(state.byUniqueId, params.uniqueId, { items, pagination, params });
        },
        POSTS_UPDATE_ITEM_WITH_ID(state, payload) {
            const { updatedItem, uniqueId } = payload;
            let newItems: Post[] = [];
            if (!uniqueId) {
                return ;
            }
            if (state.byUniqueId[uniqueId] && state.byUniqueId[uniqueId].items) {
                newItems = state.byUniqueId[uniqueId].items;
            }
            newItems = newItems.map((item) => item.id === updatedItem.id ? { ...item, ...updatedItem } : item);

            Vue.set(
                state.byUniqueId[uniqueId],
                'items',
                newItems
            );
        },
        POSTS_UPDATE_ITEMS_WITH_ID(state, payload) {
            const { items, pagination, uniqueId } = payload;
            Vue.set(
                state.byUniqueId[uniqueId],
                'items',
                state.byUniqueId[uniqueId].items.concat(...items),
            );
            Vue.set(state.byUniqueId[uniqueId], 'pagination', pagination);
        },
        POSTS_SET_MOST_RECENT_ITEMS(state, { items }) {
            Vue.set(state, 'mostRecent', items);
        },
        POSTS_SET_SELECTED_POST(state, item) {
            Vue.set(state, 'selectedPost', item);
        },
        POST_SET_LAST_POST_DATA(state, lastPost) {
            state.lastPostData = lastPost;
            localStorage.lastPostData = JSON.stringify(lastPost);
        },
        POST_DELETE_SUCCESS(state, postId) {
            const itemsByUniqueId = Object.values(state.byUniqueId).map(value => value.items);
            const allLists = [state.items, ...itemsByUniqueId];
            for (const list of allLists) {
                if (list && list.findIndex) {
                    const index = list.findIndex(post => post.id === postId);
                    if (index > -1) {
                        list.splice(index, 1);
                    }
                }
            }
        }
    },
    actions: {
        ...store.actions,
        // async uploadPostPhoto(context, { file, onUploadProgress }: { file: File, onUploadProgress?: Function }): Promise<VuexActionResult> {
        //     const formData = new FormData();
        //     formData.append('file', file);
        //     const uploadReq = await context.rootGetters.apiClient.post(`/uploads/photo`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data',
        //         },
        //         onUploadProgress
        //     });
        //     return new VuexActionResult({ data: uploadReq.data});
        // },
        async uploadMedia(context, { file, onUploadProgress }: { file: File, onUploadProgress?: Function }): Promise<VuexActionResult> {
            try {
                const formData = new FormData();
                formData.append('file', file);
                const endpoint = file.type.startsWith('video/') ? '/uploads/video' : '/uploads/photo';
                const response = await context.rootGetters.apiClient.post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress
                });
                return new VuexActionResult({ data: response.data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async createPost(context, postPayload: PostCreationPayload & { selectedMediaCount: number }): Promise<VuexActionResult> {
            if (!navigator.onLine) {
                return new VuexActionResult({ error: new Error('offline') });
            }
            try {
                const { data } = await context.rootGetters.apiClient.post('/posts', {
                    post: { type: 'observation', ...postPayload, selectedMediaCount: undefined },
                });
                const post: Post = data.posts; // Note: `posts` here is a single object.
                // Confirm it was actually stored. Not sure why the server would respond with success
                // if the sighting wasn't actually saved, but we've had reports of it.
                await new Promise(resolve => setTimeout(resolve, 250));
                const { data: confirmationData } = await context.rootGetters.apiClient.get(`/posts/${post.id}`);
                if (confirmationData.meta.total !== 1) {
                    throw new Error('Confirmation of sighting post failed.');
                }
                const contextInvestigations = context.rootState.investigations.items;
                const investigations = post.investigations.map(id => contextInvestigations.find(i => i.id === id)) as Investigation[];
                trackPostCreation(post, investigations, postPayload.selectedMediaCount);
                return new VuexActionResult({ data });
            } catch (error) {
                const isServerError = error.response?.status >= 500 && error.response?.status < 600;
                if (postPayload.retry > 0 && (!error.response || isServerError)) {
                    await new Promise(resolve => setTimeout(resolve, 2000));
                    postPayload.retry -= 1;
                    return context.dispatch('createPost', postPayload);
                } else {
                    return new VuexActionResult({ error });
                }
            }
        },
        async updatePost(context, { post, updates }: { post: Post, updates: Partial<Post> }) {
            const response = await context.rootGetters.apiClient.patch(`/posts/${post.id}`, updates);

            if (response.error) {
                return new VuexActionResult({ error: response.error });
            }

            const updatedPost: Post = response.data?.observations[0];
            Object.entries(updatedPost).forEach(([key, value]) => Vue.set(post, key, value));

            return new VuexActionResult({ data: post });
        },
        async deletePost(context, { id, noAlert }): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.delete(`/posts/${id}`);
                context.commit('POST_DELETE_SUCCESS', id);
                if (!noAlert) {
                    context.dispatch('alertUser', { type: 'info', message: 'Post successfully removed' });
                }
                return new VuexActionResult({ data });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not delete post' });
                return new VuexActionResult({ error });
            }
        },
        async fetchGeopostsByRegion(context, params): Promise<VuexActionResult> {
            const reqparams: any = {
                region: params.regionId,
                investigation: params.investigation.id,
            };
            if (params.withImage) {
                reqparams.withImage = true;
            }
            if (params.structuredQuestion && params.structuredQuestion.length) {
                reqparams.structuredQuestion = params.structuredQuestion;
            }
            if (params.from || params.to) {
                reqparams.observedAt = {};
                if (params.from) {
                    reqparams.observedAt['>='] = params.from;
                }
                if (params.to) {
                    reqparams.observedAt['<'] = moment(params.to).add(1, 'day').format('Y-MM-DD');
                }
            }
            const { data } = await context.rootGetters.apiClient.post( // TODO: Why is this a POST?
                '/reports/aggregate-per-region/observations',
                reqparams,
                { baseURL: `${API_HOST}/api/v2`},
            );
            if (data.features === null) {
                data.features = [];
            }
            return new VuexActionResult({ data });
        },
        async getGeoposts(context, observationIds): Promise<VuexActionResult> {
            try {
                const result = await context.rootGetters.apiClient.post('/reports/observations', {
                    ids: observationIds,
                });
                const posts = await aggregatePostData(
                    context,
                    result.data.posts,
                    result.data.media,
                    result.data.investigations,
                    result.data.users,
                    result.data.comments,
                    result.data.weatherUnits,
                );
                return new VuexActionResult({ data: posts });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async fetchMostRecentPosts(context): Promise<VuexActionResult> {
            try {
                const result = await context.rootGetters.apiClient.get('/posts/most-recent', {});
                const posts = await aggregatePostData(
                    context,
                    result.data.posts,
                    result.data.photos, // Response doesn't include `media`
                    result.data.investigations,
                    result.data.users,
                    result.data.comments,
                    result.data.weatherUnits,
                );
                const uniquePosts = uniqBy(posts, 'id'); // API returns dupes--why?
                context.commit('POSTS_SET_MOST_RECENT_ITEMS', { items: uniquePosts });
                return new VuexActionResult({ data: posts });
            } catch (error) {
                context.commit(mutations.POSTS_SET_ASYNC_STATUS, { status: 'failure' });
                context.dispatch('alertUser', { type: 'error', message: 'Could not fetch posts' });
                return new VuexActionResult({ error });
            }
        },
        async fetchPostById(context, params: { id: string }): Promise<VuexActionResult> {
            try {
                const {data} = await context.rootGetters.apiClient.get(`/posts/${params.id}`);
                const results = await aggregatePostData(context, data.posts, data.media, data.investigations, data.users, data.comments, data.weatherUnits);
                // We get back an empty array when the post doesn't exist.
                if (data.posts.length === 0) {
                    throw new Error('POST_NOT_FOUND');
                }
                const thepost = results[0];
                const commentReq = await context.rootGetters.apiClient.get(`/comments?post=${params.id}`);
                const comments = commentReq.data.comments.map((c: Comment) => {
                    c.userObj = commentReq.data.users.find((u: User) => u.id === c.user);
                    return c;
                });
                thepost.commentObjs = comments;
                context.commit('POSTS_SET_SELECTED_POST', thepost);
                return new VuexActionResult({ data: thepost });
            } catch (error) {
                return new VuexActionResult({ error });
            }

        },
        async fetchPosts(context, paramsAndIgnoreErrors: PostParameters & { ignoreErrors?: boolean }): Promise<VuexActionResult> {
            const { ignoreErrors, ...params } = paramsAndIgnoreErrors;
            context.commit(mutations.POSTS_SET_ASYNC_STATUS, { status: 'pending' });
            try {
                const result = await context.rootGetters.apiClient.get('/posts' + createQueryParams(params), {});
                const posts = await aggregatePostData(
                    context,
                    result.data.posts,
                    result.data.media,
                    result.data.investigations,
                    result.data.users,
                    result.data.comments,
                    result.data.weatherUnits,
                );
                const pagination = {
                    totalItems: result.data.meta.total,
                    page: result.data.page,
                    perPageCount: result.data.perPageCount,
                };
                if (params.uniqueId) {
                    context.commit(
                        'POSTS_SET_ITEMS_WITH_ID',
                        {
                            items: posts,
                            uniqueId: params.uniqueId,
                            pagination,
                            params,
                        },
                    );
                } else {
                    context.commit(mutations.POSTS_SET_ITEMS, {
                        items: posts,
                        uniqueId: params.uniqueId,
                        pagination,
                        params,
                    });
                }
                context.commit(mutations.POSTS_SET_ASYNC_STATUS, { status: 'success' });
                return new VuexActionResult({ data: posts });
            } catch (error) {
                context.commit(mutations.POSTS_SET_ASYNC_STATUS, { status: 'failure' });
                if (!ignoreErrors) context.dispatch('alertUser', { type: 'error', message: 'Could not fetch posts' });
                return new VuexActionResult({ error });
            }
        },
        async fetchPostWeatherContext(context, { lnglat, date }: { lnglat: [number, number], date: string}): Promise<VuexActionResult> {
            try {
                const averageReq = context.rootGetters.apiClient.get('https://data.iseechange.org/app/v1/gistemp/averages', {
                    params: {
                        lon: lnglat[0],
                        lat: lnglat[1],
                        time: moment(date).format('YYYY-MM-DD'),
                    }
                });
                const co2Req = context.rootGetters.apiClient.get('https://data.iseechange.org/app/v1/oco2/datapoints/closest', {
                    params: {
                        lon: lnglat[0],
                        lat: lnglat[1],
                        time: moment(date).format('YYYY-MM-DD'),
                    }
                });
                const responses = await Promise.all([averageReq, co2Req]);
                return new VuexActionResult({ data: responses.map((r) => r.data) });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async fetchMorePosts(context, { params, pagination }): Promise<VuexActionResult> {
            const queryparams = createQueryParams({...params, page: pagination.page });
            const result = await context.rootGetters.apiClient.get(
                `/posts${queryparams}`,
            {});
            const posts = await aggregatePostData(
                context,
                result.data.posts,
                result.data.media,
                result.data.investigations,
                result.data.users,
                result.data.comments,
                result.data.weatherUnits,
            );
            const newpagination = {
                totalItems: result.data.meta.total,
                page: result.data.page,
                perPageCount: result.data.perPageCount,
            };
            context.commit(
                'POSTS_UPDATE_ITEMS_WITH_ID',
                {
                    items: posts,
                    uniqueId: params.uniqueId,
                    pagination: newpagination,
                    params,
                },
            );
            return new VuexActionResult({ data: posts });
        },
        async addCommentToPost(context, { postId, comment, mediaIds, uniqueId }: { postId: string, comment: string, mediaIds: string, uniqueId: string }): Promise<VuexActionResult> {
            try {
                const { data } = await context.rootGetters.apiClient.post('/comments', {
                    comment: {
                        post: postId,
                        textBody: comment,
                        media_ids: mediaIds
                    },
                });
                const returnedComment = orderBy(data.comments, 'createdAt', 'desc')[0];
                const { data: refetchedPost } = await context.dispatch('fetchPostById', { id: postId });
                const investigation = context.rootState.investigations.items.find(i => i.id === refetchedPost.investigation);
                trackCommentCreation(returnedComment, refetchedPost, investigation);
                context.commit(
                    'POSTS_UPDATE_ITEM_WITH_ID',
                    {
                        updatedItem: refetchedPost,
                        uniqueId,
                    },
                );
                return new VuexActionResult({ data });
            } catch (error) {
                context.dispatch('alertUser', { type: 'error', message: 'Could not create comment' });
                return new VuexActionResult({ error });
            }
        },
        async updateComment(context, { comment, updates }: { comment: Comment, updates: Partial<Comment> }): Promise<VuexActionResult> {
            let response;
            if (updates.deletedAt || updates.textBody?.trim() === '') {
                response = await context.rootGetters.apiClient.delete(`/comments/${comment.id}`);
            } else {
                response = await context.rootGetters.apiClient.put(`/comments/${comment.id}`, updates);
            }

            const updatedComment: Comment = response.data?.comment;

            if (updatedComment) {
                Object.entries(updatedComment).forEach(([key, value]) => Vue.set(comment, key, value));
            }

            return new VuexActionResult({
                error: response.error,
                data: comment,
            });
        },
        async reportPost(context, { postId, reason }: { postId: Post['id'], reason: string }) {
            try {
                const userId = context.rootState.account.currentUser?.id;
                const { data } = await context.rootGetters.apiClient.post('/flags', {
                    userId,
                    postId,
                    reason,
                    type: 'post',
                });
                return new VuexActionResult({ data });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
    },
};

export default postsStore;


