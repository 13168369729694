import { Geolocation } from '@capacitor/geolocation';
import { reverseGeocode } from '@/util.mapbox';
import { MBAddressObj, AddressComponents, LngLat } from '@/types';

export async function getActiveLocation() {
    const result: { lngLat: LngLat | null, location: null | MBAddressObj, error: null | string } = {
        lngLat: null,
        location: null,
        error: null,
    };
    if (Geolocation) {
        try {
            const coordinates = await Geolocation.getCurrentPosition({ enableHighAccuracy: true, maximumAge: 60_000 });
            result.lngLat = [coordinates.coords.longitude, coordinates.coords.latitude];
            let revGeocoded = await reverseGeocode(result.lngLat, {
                limit: 1,
                types: ['address']
            });
            if (revGeocoded.features.length === 0) {
                // Prefer "address" type, but if nothing is found try again and accept anything.
                revGeocoded = await reverseGeocode(result.lngLat, {
                    limit: 1,
                });
            }
            if (revGeocoded.features[0]) {
                result.location = revGeocoded.features[0];
            } else {
                result.error = 'Sorry, we couldn\'t automatically find your location.';
            }
        } catch (error) {
            if (error.message.includes('denied') || error.message.includes('permission')) {
                result.error = 'Update your phone\'s settings to allow ISeeChange to automatically find your location.';
            } else {
                console.error(error);
                result.error = 'Sorry, we couldn\'t automatically determine your location.';
            }
        }
    } else {
        result.error = 'Geolocation unavailable';
    }
    return result;
}

export function shortAddressFromComponents (ac: AddressComponents) {
    let country = ac.country;
    if (ac.countryCode) {
        country = ac.countryCode.toUpperCase();
    }
    return [ac.state, country].filter(Boolean).join(', ');
}

export function longAddressFromComponents(ac: AddressComponents) {
    let country = ac.country;
    if (ac.countryCode) {
        country = ac.countryCode.toUpperCase();
    }
    const city = ac.city || ac.place;
    return [city, ac.state, country].filter(Boolean).join(', ');
}
