import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import { apiClient } from '../api-client';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { Share } from '@capacitor/share';
import { RootState, AlertData, MBAddressObj } from '@/types';
import { VuexActionResult } from '@/store/util';
import investigations from './investigations.module';
import posts from './posts.module';
import projects from './projects.module';
import surveys from './surveys.module';
import stories from './stories.module';
import account from './account.module';
import { getActiveLocation } from '@/util.location';
import { VueConfiguration } from 'vue/types/vue';
import { trackReferringPartner } from '@/tracking';
import { CLIENT_ORIGIN } from '@/config';

Vue.use(Vuex);

let sessionPartner = null;
try {
    sessionPartner = sessionStorage.partner;
} catch (error) {
    // Pass
}

const storeOpts: StoreOptions<RootState> = {
    state: {
        apiClient: apiClient,
        overlay: {
            component: null,
            type: '',
            shown: false,
        },
        platform: '',
        partner: sessionPartner,
        previousRoutes: [],
        network: {
            // Default network activity is online wifi since network isn't implemented for web...
            connected: true,
            connectedType: 'wifi',
        },
        activeLocation: null,
        share: {
            content: {},
            shown: false,
        },
        alerts: [],
    } as any as RootState,
    actions: {
        async getActiveLocation(context): Promise<VuexActionResult> {
            // TODO: better error handling w/ getActiveLocation
            const result = await getActiveLocation();
            context.commit('SET_ACTIVE_LOCATION', result.location);
            return new VuexActionResult({ data: result });
        },
        setPartner(context, partner: string | null): void {
            context.commit('SET_PARTNER', partner || null);
        },
        alertUser(context, data: AlertData): VuexActionResult {
            const suppressed = data.type === 'error' && context.state.alerts[context.state.alerts.length - 1]?.suppressFurtherErrors;
            if (!suppressed) {
                context.commit('ALERT_USER', data);
            }
            return new VuexActionResult({});
        },
        dismissAlert(context, index = 0): VuexActionResult {
            context.commit('DISMISS_ALERT', index);
            return new VuexActionResult({});
        },
        async shareContent(context, shareContent: RootState['share']['content']): Promise<VuexActionResult> {
            const shareData = {
                dialogTitle: shareContent.dialogTitle ?? 'Share from ISeeChange',
                url: shareContent.post ? `${CLIENT_ORIGIN}/share/sightings/${shareContent.post.id}` : shareContent.path,
                title: shareContent.post ? 'ISeeChange sighting' : shareContent.title,
                text: shareContent.post ? `“${shareContent.post.textBody}”` : shareContent.text,
            };

            try {
                await Share.share(shareData);
            } catch (error) {
                // Capacitor throws a string here, yay.
                if (!error.message?.includes('cancel')) {
                    context.commit('SET_SHARE_CONTENT', {
                        ...shareData,
                        url: shareContent.post ? `` : shareData.url,
                        post: shareContent.post,
                    });
                    context.commit('SHOW_SHARE', true);
                }
            }
            return new VuexActionResult({});
        },
        showOverlay(context, params: { component: VueConfiguration, type: string }): VuexActionResult {
            context.commit('SET_OVERLAY', params);
            return new VuexActionResult({ data: params });
        },
        changeRoute(context, { to, from }): VuexActionResult {
            context.commit('UPDATE_HISTORY', { to, from });
            return new VuexActionResult({ data: context.state.previousRoutes });
        },
    },
    mutations: {
        SET_OVERLAY(state, params: { component: VueConfiguration, type: string }) {
            state.overlay.component = params.component;
            state.overlay.component = params.type;
        },
        SET_ACTIVE_LOCATION(state, location: MBAddressObj) {
            state.activeLocation = location;
        },
        SET_SHARE_CONTENT(state, shareContent) {
            state.share.content = shareContent;
        },
        SHOW_SHARE(state, val) {
            state.share.shown = val;
        },
        UPDATE_NETWORK_STATUS(state, status) {
            Vue.set(state, 'network', status);
        },
        SET_PARTNER(state, partner: string | null) {
            state.partner = partner;
            if (partner) {
                trackReferringPartner(partner);
                try {
                    sessionStorage.partner = partner;
                } catch (error) {
                    // Pass
                }
            } else {
                try {
                    delete sessionStorage.partner;
                } catch (error) {
                    // Pass
                }
            }
        },
        ALERT_USER(state, data: AlertData) {
            state.alerts.push(data);
        },
        DISMISS_ALERT(state, index: number) {
            state.alerts.splice(index, 1);
        },
        SET_PLATFORM(state, platform) {
            Vue.set(state, 'platform', platform);
        },
        UPDATE_HISTORY(state, { to, from }) {
            const mostRecent = state.previousRoutes[state.previousRoutes.length - 1];
            if (mostRecent !== undefined && to.fullPath === mostRecent.fullPath) {
                // No way to be sure, but the user probably hit the back button.
                state.previousRoutes.splice(-1, 1);
            } else {
                state.previousRoutes.push(from);
            }
        },
    },
    getters: {
        apiClient: (state: RootState) => state.apiClient,
        isOnline: (state: RootState) => state.network.connected,
        isWifi: (state: RootState) => state.network.connectedType === 'wifi',
    },
    modules: {
        investigations,
        posts,
        projects,
        surveys,
        account,
        stories,
    },
};

const store = new Store<RootState>(storeOpts);

if (Network) {
    Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed', status);
        store.commit('UPDATE_NETWORK_STATUS', status);
    });
}
if (Device) {
    Device.getInfo().then((data) => {
        store.commit('SET_PLATFORM', data.platform);
    }).catch(() => {
        store.commit('SET_PLATFORM', 'web');
    });
}



export default store;
