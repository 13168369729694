import originalMessages from './messages.json';

// TODO: Translate this in the PostsList component.
// query: {
//     miles: 'mile | miles',
//     fromLocation: 'from {location}',
//     global: 'from around the world',
//     relatingTo: 'relating to {topic}',
//     onDate: 'on {date}',
//     betweenDate: 'between {start} and {end}',
//     beforeDate: 'before {date}',
//     afterDate: 'after {date}',
//     containing: 'containing',
// },

function blankOutStrings(input: any) {
    const output: any = {};

    Object.entries(input).forEach(([key, value]) => {
        if (typeof value === 'object') {
            output[key] = blankOutStrings(value);
        } else if (typeof value === 'string') {
            output[key] = value.split('|').map(segment => {
                // TODO: Support `{count}`.
                return segment.split(/\s+/).map(word => {
                    return '–'.repeat(word.length);
                }).join(' ');
            }).join('|');
        }
    });

    return output;
}

// TODO: The type is available, but I can't get it to accept new values.
// import { LocaleMessages } from 'vue-i18n';

const messages: any = { ...originalMessages };
const sourceLocale = 'en';
const devLocale = 'xx';

messages[sourceLocale].languages[devLocale] = 'Obscure translated strings';
messages[devLocale] = blankOutStrings(messages[sourceLocale]);
messages[devLocale].prismicLocale = messages[sourceLocale].prismicLocale;

export { messages, sourceLocale, devLocale };
