import { Comment, Investigation, Photo, Post, PostParameters, User, Video, WeatherUnits } from '@/types';
import { Device } from '@capacitor/device';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import store from './store';

export const findUserById = (userId: any, userArr: any[]) => {
    for (let i = 0; i < userArr.length; ++i) {
        if (userArr[i].id === userId) {
            return userArr[i];
        }
    }
    return {};
};

export const findItemById = (id: any, items: any[]) => {
    for (let i = 0; i < items.length; ++i) {
        if (items[i].id === id) {
            return items[i];
        }
    }
    return {};
};

// Let's keep this on hand in case we've accidentally missed adding this field to any endpoints.
let mostRecentlyAggregatedWeatherUnits = {} as WeatherUnits;

export const aggregatePostData = async (context: any, posts: Post[] = [], media: (Photo | Video)[] = [], investigations: Investigation[] = [], users: User[] = [], comments: Comment[] = [], weatherUnits: WeatherUnits) => {
    const returnDataBundle = [];
    for (let i = 0; i < posts.length; ++i) {
        const post = posts[i];
        if (!post.user) {
            console.warn('USER FOR POST NOT FOUND, SKIPPING POST');
            continue;
        }
        post.userObj = findUserById(post.user, users);
        if (post.investigation) {
            // post.structuredData = formatStructuredData(post);
            const { data } = await context.dispatch('fetchInvestigationById', { id: post.investigation });
            post.investigationObj = data;
            try {
                // TODO: What is this?
            } catch (err) {
                post.investigationObj = null;
            }
        }
        post.investigationObjs = [];
        for (const investigationId of post.investigations) {
            const investigation = investigations.find(investigation => investigation.id === investigationId);
            if (investigation) {
                post.investigationObjs.push(investigation);
            }
        }
        post.photoObjs = [];
        if (post.photos) {
            for (let j = 0; j < post.photos.length; ++j) {
                post.photoObjs.push(findItemById(post.photos[j], [...media, ...post.media]));
            }
        }
        post.photoObjs = orderBy(post.photoObjs, 'created_at') as Post['photoObjs'];

        post.commentObjs = [];
        if (comments && post.comments) {
            for (let j = 0; j < post.comments.length; ++j) {
                const comObj = comments.find((c) => c.id === post.comments[j]);
                if (comObj) {
                    comObj.userObj = findUserById(comObj.user, users);
                    post.commentObjs.push(comObj);
                }
            }
        }
        if (weatherUnits) {
            mostRecentlyAggregatedWeatherUnits = weatherUnits;
        }
        post.weatherUnits = mostRecentlyAggregatedWeatherUnits;
        returnDataBundle.push(post);
    }
    return returnDataBundle;
};

export const POSTS_PER_PAGE = 30;

const METERS_PER = {
    mi: 1609.344,
    ft: 1609.344 / 5280,
    m: 1,
    km: 1000,
};

type ValidDistanceUnit = keyof typeof METERS_PER;

export const DEFAULT_DISTANCE_UNIT = 'mi'; // Distance was previously unitless.

export function splitDistanceAndUnit(distanceValue: string) {
    const splitResult = distanceValue.toLowerCase().split(/([\d.,]+)/); // Include the comma for future European support.
    // Example split outputs: '1' -> ['', '1', '']; 'ft' -> ['ft']; '1ft' -> ['', '1', 'ft']
    const unit = splitResult.pop()?.trim() ?? '';
    const distance = splitResult.pop() ?? '0';
    return {
        distance: parseFloat(distance),
        unit: unit as ValidDistanceUnit || null,
    };
}

export const createQueryParams = (params: PostParameters) => {
    let filterString = `?type[]=anomaly&type[]=observation&type[]=investigation`;
    if (params.limit !== Infinity) {
        filterString += `&page=${params.page || 1}&limit=${params.limit || POSTS_PER_PAGE}`;
    }
    if (params.format) {
        filterString += `&format=${params.format}`;
    }
    if (params.search) {
        const tags = params.search.split(' ').join(',');
        filterString += `&or[0][tags][]=${tags}`;
        filterString += `&or[1][textBody][contains]=${tags}`;
        filterString += `&or[2][shortAddress][contains]=${tags}`;
        filterString += `&or[3][longAddress][contains]=${tags}`;
    }
    filterString += `&sort=${params.sortBy ? params.sortBy : 'observedAt'}+DESC`;
    if (Array.isArray(params.investigationId)) {
        params.investigationId.forEach(id => {
            filterString += `&investigations=${id}`;
        });
    } else if (typeof params.investigationId === 'string') {
        filterString += `&investigation=${params.investigationId}`;
    }
    filterString += params.userId ? `&user=${params.userId}` : '';
    filterString += params.fromDate ? `&observedAt[>=]=${moment(`${params.fromDate}${params.fromDate.includes('T') ? '' : 'T00:00:00'}`).toISOString()}` : '';
    filterString += params.toDate ? `&observedAt[<=]=${moment(`${params.toDate}${params.toDate.includes('T') ? '' : 'T23:59:59'}`).toISOString()}` : '';
    if (params.regionId) {
        filterString += `&region=${params.regionId}`;
    }
    if (params.lngLat) {
        filterString += '&near[fieldName]=geoPoint' +
            '&near[coordinates][]=' + params.lngLat[0] +
            '&near[coordinates][]=' + params.lngLat[1];
        if (params.distance) {
            const { distance, unit } = splitDistanceAndUnit(params.distance);
            if (distance !== 0) {
                if (unit && !METERS_PER[unit]) {
                    console.error(`Distance has unsupported unit: "${params.distance}"`);
                }

                const distanceInMeters = distance * (METERS_PER[unit ?? DEFAULT_DISTANCE_UNIT] ?? METERS_PER[DEFAULT_DISTANCE_UNIT]);
                filterString += `&near[maxDistance]=${distanceInMeters}`;
            }
        }
    }
    if (params.withImage) {
        filterString += `&withImage=${params.withImage}`;
    }
    if (params.structuredQuestion) {
        filterString += `&structuredQuestion=${params.structuredQuestion}`;
    }


    return filterString;
};

function downloadFromWeb(data: string | Blob, filename: string, type?: string): string {
    const blob = data instanceof Blob ? data : new Blob([data], { type });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.style.position = 'fixed';

    document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href), 60_000);
    return link.href;
}

export async function download(data: string | Blob, filename: string, type?: string) {
    const deviceInfo = await Device.getInfo();

    if (deviceInfo.platform === 'web') {
        return downloadFromWeb(data, filename, type);
    } else {
        // NOTE: This works inconsistently.
        // Keeping it for future reference, but don't depend on it.
        try {
            const text = typeof data === 'string' ? data : await data.text();
            await Filesystem.writeFile({
                path: filename,
                data: text,
                directory: Directory.Documents,
                encoding: Encoding.UTF8,
            });

            alert(`Check your Downloads folder for “${filename}”!`);
        } catch (error) {
            alert(`Failed to download “${filename}” (${error})`);
        }
    }
}

export async function downloadZip(postId: string) {
    const response = await fetch(`${store.state.apiClient.defaults.baseURL}/posts/${postId}?format=zip`, {
        headers: {
            accept: 'application/zip',
            authorization: store.state.apiClient.defaults.headers.Authorization,
        },
    });

    const blob = await response.blob();
    download(blob, `${postId}.zip`, 'application/zip');
}
