import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { devLocale, messages, sourceLocale } from './dev-helpers';

Vue.use(VueI18n);

function figureOutStartingLocale(userLocale = '') {
    if (!messages[userLocale]) {
        userLocale = '';
    }

    if (!userLocale) {
        const supportedLocales = Object.keys(messages);
        const lowerSupportedLocales = supportedLocales.map(locale => locale.toLowerCase());

        // Pick the first language in the browser's list of langauges that is directly supported.
        for (const language of navigator.languages) {
            const index = lowerSupportedLocales.indexOf(language.toLowerCase());
            if (index !== -1) {
                userLocale = supportedLocales[index];
                break;
            }
        }

        if (!userLocale) {
            // Pick the first one where at least the language (e.g. "en" in "en-US") matches.
            for (const language of navigator.languages) {
                const parts = language.split('-');
                const justTheLanguage = parts[Math.max(0, parts.length - 2)];
                const index = lowerSupportedLocales.indexOf(justTheLanguage.toLowerCase());
                if (index !== -1) {
                    userLocale = supportedLocales[index];
                    break;
                }
            }
        }
    }

    if (!userLocale) {
        // Fall back to English.
        userLocale = sourceLocale;
    }

    return userLocale;
}

const langFromQuery = new URLSearchParams(location.search).get('lang')?.toLowerCase();

export const i18n = new VueI18n({
    locale: figureOutStartingLocale(langFromQuery || localStorage.userLang),
    fallbackLocale: 'en',
    messages,
});

export { devLocale, messages, sourceLocale };
