import Vue from 'vue';
import { Module } from 'vuex';
import { Project, RootState } from '@/types';
import { apiClient } from '@/api-client';
import { VuexActionResult } from './util';

type SlugPair = `${Project['clientGroup']['slug']}/${Project['projectDetails']['slug']}`;

export type ProjectsState = {
    items: Record<SlugPair, Project>;
    fetchedAt: Record<SlugPair, ReturnType<typeof Date.now>>;
    pending: Record<
        Project['id'],
        Record<keyof Project | keyof Project['projectDetails'], number>
    >;
    lastSetPending: number;
};

const FIVE_MINUTES = 5 * 60 * 1000;
const TIME_TO_CACHE = FIVE_MINUTES;

function getSlugPair(project: Project): SlugPair {
    return `${project.clientGroup.slug}/${project.projectDetails.slug}`;
}

const projects: Module<ProjectsState, RootState> = {
    state: {
        items: {},
        fetchedAt: {},
        pending: {},
        lastSetPending: 0,
    },

    getters: {
        project(state) {
            return function(groupSlug: Project['clientGroup']['slug'], slug: Project['projectDetails']['slug']): Project | null {
                return state.items[`${groupSlug}/${slug}`] ?? null;
            };
        },

        projectPending(state) {
            return function(id: Project['id']): Record<string, boolean> {
                state.lastSetPending; // Force reactivity.
                const result: Record<string, boolean> = {};
                if (state.pending[id] !== undefined) {
                    for (const [key, value] of Object.entries(state.pending[id])) {
                        result[key] = value > 0;
                    }
                }
                return result;
            };
        },
    },

    mutations: {
        REMEMBER_PROJECT(state, project: Project) {
            const existingProject = Object.values(state.items).find(p => p.id === project.id);
            const slugs = getSlugPair(project);
            Vue.set(state.items, slugs, Object.assign({}, state.items[slugs], project));
            Vue.set(state.fetchedAt, slugs, Date.now());

            // Handle slug changes!
            if (existingProject) {
                const existingProjectSlugs = getSlugPair(existingProject);
                const slugsChanged = existingProjectSlugs !== slugs;
                if (slugsChanged) {
                    // Notify the old copy that it's changed, so any component that's fetched it updates its reference.
                    Vue.set(state.items, existingProjectSlugs, Object.assign({}, state.items[existingProjectSlugs], project));
                    Vue.nextTick().then(() => {
                        // Then delete it.
                        Vue.delete(state.items, existingProjectSlugs);
                    });
                }
            }
        },

        SET_PENDING(state, { id, changes, done } : { id: Project['id'], changes: Partial<Project>, done?: boolean }) {
            state.lastSetPending = Date.now();
            Vue.set(state.pending, id, state.pending[id] ?? {});
            const changingKeys = [
                ...Object.keys(changes) as (keyof Project)[],
                ...Object.keys(changes.projectDetails ?? {}) as (keyof ProjectsState['pending'][Project['id']])[]
            ];
            for (const key of changingKeys) {
                Vue.set(state.pending[id], key, state.pending[id][key] ?? 0);
                state.pending[id][key] += done ? -1 : +1;
            }
        },
    },

    actions: {
        async fetchProjects(context): Promise<VuexActionResult> {
            try {
                const endpoint = '/local-projects';
                const response = await apiClient.get(endpoint);
                for (const project of response.data.localProjects) {
                    context.commit('REMEMBER_PROJECT', project);
                }
                return new VuexActionResult({ data: response.data.localProjects });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },

        async fetchProject(context, { groupSlug, slug, skipCache }: { groupSlug: string, slug: string, skipCache?: boolean }): Promise<VuexActionResult> {
            try {
                const slugs: SlugPair = `${groupSlug}/${slug}`;
                if (skipCache || context.state.fetchedAt[slugs] === undefined || Date.now() - context.state.fetchedAt[slugs] > TIME_TO_CACHE) {
                    const endpoint = `/local-projects/${slugs}`;
                    const response = await apiClient.get(endpoint);
                    const project = response.data;
                    if (project) {
                        context.commit('REMEMBER_PROJECT', project);
                    } else {
                        throw new Error('PROJECT_NOT_FOUND');
                    }
                }
                return new VuexActionResult({ data: context.state.items[slugs] });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },

        async updateProject(context, { id, modifyPayload, ...changes }: Partial<Project> & { modifyPayload?: Function }) {
            try {
                context.commit('SET_PENDING', { id, changes });
                if (modifyPayload !== undefined) {
                    const modifications = await modifyPayload();
                    const addedKeys = Object.keys(modifications).filter(key => !(key in changes));
                    if (addedKeys.length !== 0) {
                        console.warn(`Added keys, pending state won't be set: ${addedKeys.join(', ')}`);
                    }
                    changes = { ...changes, ...modifications };
                }
                const endpoint = `/local-projects/${id}`;
                const response = await apiClient.patch(endpoint, changes);
                context.commit('REMEMBER_PROJECT', response.data);
                return new VuexActionResult({ data: response.data });
            } catch (error) {
                return new VuexActionResult({ error });
            } finally {
                context.commit('SET_PENDING', { id, changes, done: true });
            }
        },
    },
};

export default projects;
