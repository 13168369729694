import Vue from 'vue';
import { Module } from 'vuex';
import { RootState, Survey, SurveyQuestion, SurveyRequest } from '@/types';
import { VuexActionResult } from './util';
import { apiClient } from '@/api-client';

export type SurveysState = {
    surveys: Record<Survey['id'], Survey>;
    requests: Record<SurveyRequest['id'], SurveyRequest>;
};

const surveys: Module<SurveysState, RootState> = {
    state: {
        requests: {},
        surveys: {},
    },

    getters: {
        surveyRequests(state) {
            return Object
                .values(state.requests)
                .sort((r1, r2) => r1.createdAt < r2.createdAt ? -1 : r1.createdAt > r2.createdAt ? 1 : 0)
                .filter(r => !r.dismissedAt);
        },

        survey(state) {
            return function(id: Survey['id']): Survey | null {
                return state.surveys[id] ?? null;
            };
        },
    },

    mutations: {
        SET_REQUESTS(state, requests: SurveyRequest[]) {
            Vue.set(state, 'requests', {});
            for (const request of requests) {
                Vue.set(state.requests, request.id, request);
            }
        },

        REMEMBER_SURVEY(state, survey: Survey) {
            Vue.set(state.surveys, survey.id, survey);
        },
    },

    actions: {
        async fetchSurveyRequests(context) {
            try {
                const { data } = await apiClient.get(`/surveys/requests`);
                const surveyRequests: SurveyRequest[] = data.surveyRequests;
                context.commit('SET_REQUESTS', surveyRequests);
                return new VuexActionResult({ data: context.getters.surveyRequests });
            } catch (error) {
                context.commit('SET_REQUESTS', []);
                return new VuexActionResult({ error });
            }
        },

        async fetchSurvey(context, id: Survey['id']): Promise<VuexActionResult> {
            try {
                const { data } = await apiClient.get(`/surveys/${id}`);
                context.commit('REMEMBER_SURVEY', data.survey);
                return new VuexActionResult({ data: context.getters.survey(id) });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },

        async respondToSurvey(
            context,
            { surveyId, answers }: { surveyId: Survey['id'], answers: Record<SurveyQuestion['id'], unknown>[] }
        ): Promise<VuexActionResult> {
            try {
                // TODO: Check for existing response at
                // `/surveys/${surveyId}/responses?userId=${userId}`
                // instead of storing answers locally.
                // const { data: creation } = await apiClient.post(`/surveys/${surveyId}/responses`);
                // await apiClient.patch(`/surveys/${surveyId}/responses/${creation.surveyResponse.id}`, { answers });
                // const { data: submission } = await apiClient.post(`/surveys/${surveyId}/responses/${creation.surveyResponse.id}/submit`);

                // For now, we keep the answers in localStorage, and we'll send everything in one request:
                const { data: response } = await apiClient.post(`/surveys/${surveyId}/responses`, { answers, submit: true });

                const request = Object.values(context.state.requests).find(request => request.survey_id === surveyId);
                if (request) {
                    Vue.delete(context.state.requests, request.id);
                }

                return new VuexActionResult({ data: response });
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },

        async dismissSurveyRequest(context, requestId: SurveyRequest['id']) {
            const request = context.state.requests[requestId];
            const now = new Date();
            try {
                await apiClient.patch(`/surveys/${request.survey_id}/requests/${request.id}`, { dismissedAt: now });
                Vue.set(context.state.requests[requestId], 'dismissedAt', now.toISOString());
            } catch (error) {
                console.error(error);
                context.dispatch('alertUser', { type: 'error', message: 'Could not dismiss survey, due to an error. Please try again.' });
            }
        }
    },
};

export default surveys;
