import axios from 'axios';
import orderBy from 'lodash/orderBy';
import Vue from 'vue';
import { Module } from 'vuex';
import { RootState, StoriesState, Story } from '@/types';

const STORIES_JSON_ENDPOINT = 'https://stories.iseechange.org/custom-post-feed-for-nasa-app/';

const stories: Module<StoriesState, RootState> = {
    state: {
        fetchedPages: 0,
        requestsInFlight: 0,
        items: {},
    },

    getters: {
        storiesRequested(state) {
            return state.requestsInFlight !== 0;
        },

        storiesByDate(state) {
            return orderBy(Object.assign(state.items), 'date', 'desc');
        },

        latestStory(state, getters): Story | undefined {
            return getters.storiesByDate[0];
        },
    },

    mutations: {
        INCREMENT_FETCHED_STORIES_PAGES(state, modifier: number = 1) {
            state.fetchedPages += modifier;
        },

        SET_STORIES_REQUESTS_IN_FLIGHT(state, inFlight: boolean = true) {
            state.requestsInFlight += inFlight ? 1 : -1;
        },

        ADD_STORIES(state, stories: Story[]) {
            for (const story of stories) {
                Vue.set(state.items, story.id, story);
            }
        },
    },

    actions: {
        async fetchStories(context, nextPage: boolean = false): Promise<void> {
            const notCalledYet = context.state.fetchedPages === 0;

            if (notCalledYet || nextPage) {
                try {
                    context.commit('INCREMENT_FETCHED_STORIES_PAGES');
                    context.commit('SET_STORIES_REQUESTS_IN_FLIGHT');

                    const response = await axios.get(STORIES_JSON_ENDPOINT, {
                        params: { 'isc_page': context.state.fetchedPages },
                    });

                    context.commit('ADD_STORIES', response.data);
                } catch (error) {
                    context.commit('INCREMENT_FETCHED_STORIES_PAGES', -1);
                    throw error;
                } finally {
                    context.commit('SET_STORIES_REQUESTS_IN_FLIGHT', false);
                }
            }
        },
    },
};

export default stories;
