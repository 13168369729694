import { StoreOptions } from 'vuex';
import Vue from 'vue';
import { BaseState } from '@/types';

export const createBaseStore = (
    name: string,
    url: string,
    uppercased = name.toUpperCase()
): { store: StoreOptions<BaseState>, mutations: any } => {
        return {
            store: {
                state: {
                    selectedItem: null,
                    items: [] as any[],
                    itemsPagination: {},
                    asyncStatus: null,
                },
                mutations: {
                    [`${uppercased}_SET_SELECTED`](state, payload) {
                        Vue.set(state, 'selectedItem', payload.selectedItem);
                    },
                    [`${uppercased}_SET_ITEMS`](state, payload) {
                        Vue.set(state, 'items', payload.items);
                        Vue.set(state, 'itemPagination', payload.itemPagination);
                    },
                    [`${uppercased}_SET_ASYNC_STATUS`](state, payload) {
                        Vue.set(state, 'asyncStatus', payload.status);
                    },
                },
                actions: {},
                getters: {},
            },
            mutations: {
                [`${uppercased}_SET_SELECTED`]: `${uppercased}_SET_SELECTED`,
                [`${uppercased}_SET_ITEMS`]: `${uppercased}_SET_ITEMS`,
                [`${uppercased}_SET_ASYNC_STATUS`]: `${uppercased}_SET_ASYNC_STATUS`,
            },
        };
};


export class VuexActionResult {
    data?: any;
    error?: Error;
    constructor(params: {data?: any, error?: Error}) {
        this.data = params.data;
        this.error = params.error;
        if (this.data) {
            // console.info('VuexActionResult Data: ', this.data);
        }
        if (this.error) {
            // console.error('VuexActionResult Error: ', this.error);
        }
    }
}
