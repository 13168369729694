import mbxClient from '@mapbox/mapbox-sdk';
import mbxGeocoding, { GeocodeRequest } from '@mapbox/mapbox-sdk/services/geocoding';
import words from 'lodash/words';
import type Mapbox from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN } from './config';
import { i18n } from './i18n';
import { AddressComponents, LngLat, MBAddressObj } from './types';

// Docs for all Mapbox services:
// https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md
// https://docs.mapbox.com/api/search/geocoding/

const baseClient = mbxClient({ accessToken: MAPBOX_ACCESS_TOKEN });
const geocodingService = mbxGeocoding(baseClient);

// Import Mapbox from its own chunk.
let AsyncMapbox: Promise<typeof Mapbox> | null = null;
export async function importMapboxGl() {
    AsyncMapbox ??= import(/* webpackChunkName: "mapbox" */ 'mapbox-gl').then(({ default: mapboxgl }) => {
        mapboxgl.accessToken = baseClient.accessToken;
        return mapboxgl;
    });

    return AsyncMapbox;
}

function cleanMapboxQueryString(address: string) {
    // Mapbox returns an error with over 20 words or 256 characters.
    // https://docs.mapbox.com/api/search/#geocoding-api-errors
    const addressWords = Array.from(new Set(words(address.toLowerCase())));
    return addressWords.slice(0, 20).join(' ').slice(0, 256);
}

export const geocode = async (query: string, params: Omit<GeocodeRequest, 'query'> = {}) => {
    const response = await geocodingService.forwardGeocode({
        query: cleanMapboxQueryString(query),
        // Default proximity to the string "ip", even though this wants coordinates:
        proximity: 'ip' as unknown as [number, number],
        language: Array.from(new Set([i18n.locale, ...navigator.languages])),
        ...params,
    }).send();
    return response.body;
};

export async function reverseGeocode(coords: LngLat, params: Omit<GeocodeRequest, 'query'> = {}) {
    const response = await geocodingService.reverseGeocode({
        query: coords,
        types: ['country', 'region', 'place', 'locality', 'neighborhood', 'address', 'poi'],
        limit: 4,
        ...params,
    }).send();
    return response.body;
}

export const parseAddressComponentsFromMBobj = (mbObj: MBAddressObj) => {
    const addressComponents: AddressComponents = {};
    addressComponents.text = mbObj.place_name;
    // if (mbObj.place_type.includes('address')) {
    //     addressComponents.streetName = mbObj.address;
    // }
    const fields = [mbObj] as any[];
    if (mbObj.context) {
        fields.push(...mbObj.context);
    }
    fields.forEach((c) => {
        const contextKey = c.id.split('.')[0];
        switch (contextKey) {
            case 'neighborhood':
                addressComponents.neighborhood = c.text;
                break;
            case 'locality':
                addressComponents.city = c.text;
                break;
            case 'postcode':
                addressComponents.zipcode = c.text;
                break;
            case 'place':
                addressComponents.place = c.text;
                break;
            case 'district':
                addressComponents.district = c.text;
                break;
            case 'region':
                addressComponents.state = c.text;
                addressComponents.stateCode = c.short_code;
                break;
            case 'country':
                addressComponents.country = c.text;
                addressComponents.countryCode = c.short_code;
        }
    });
    return addressComponents;
};

export function makeSatelliteImageSrc({
    center = [0, 0] as LngLat,
    zoom = 10,
    pin = null as LngLat | null,
    width = 640,
    height = 480,
}) {
    return [
        'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static',
        pin ? `/pin-s+44a4c4(${pin[0]},${pin[1]})` : '',
        `/${center[0]},${center[1]},${zoom},0`,
        `/${width}x${height}`,
        devicePixelRatio >= 1.5 ? '@2x' : '',
        `?access_token=${MAPBOX_ACCESS_TOKEN}`
    ].join('');
}
