import { Module } from 'vuex';
import { Investigation, RootState, InvestigationsState } from '@/types';
import { createBaseStore, VuexActionResult } from '@/store/util';

const url = '/investigations';
const {store, mutations} = createBaseStore('investigations', url);


const investigationsStore: Module<InvestigationsState, RootState> = {
    state: {
        ...store.state,
        items: [],
    },
    mutations: {
        ...store.mutations,
    },
    actions: {
        ...store.actions,
        async fetchInvestigations(context): Promise<VuexActionResult> {
            context.commit(mutations.INVESTIGATIONS_SET_ASYNC_STATUS, { status: 'pending' });
            try {
                const result = await context.rootGetters.apiClient.get(url);
                result.data.investigations.sort((a: Investigation, b: Investigation) => a.name > b.name ? 1 : -1);
                context.commit(mutations.INVESTIGATIONS_SET_ITEMS, {
                    items: result.data.investigations,
                    itemPagination: {
                        page: result.data.page,
                        pagePerCount: result.data.pagePerCount,
                        total: result.data.total,
                    },
                });
                context.commit(mutations.INVESTIGATIONS_SET_ASYNC_STATUS, { status: 'success' });
                return new VuexActionResult({ data: result.data.investigations });
            } catch (error) {
                context.commit(mutations.INVESTIGATIONS_SET_ASYNC_STATUS, { status: 'failure' });
                return new VuexActionResult({ error });
            }
        },
        async fetchInvestigationById(context, params): Promise<VuexActionResult> {
            try {
                if (context.state.items && context.state.items.length === 0) {
                    await context.dispatch('fetchInvestigations');
                }
                if (!context.state.items) {
                    return new VuexActionResult({data: null});
                }
                const investigation = context.state.items.find( (i: Investigation) => {
                    return i.id === params.id;
                });
                return new VuexActionResult({ data: investigation});
            } catch (error) {
                return new VuexActionResult({ error });
            }
        },
        async fetchAndSetInvestigation(context, params): Promise<VuexActionResult> {
            const { data } = await context.dispatch('fetchInvestigationById', params);
            if (data) {
                context.commit(mutations.INVESTIGATIONS_SET_SELECTED, {
                    selectedItem: data,
                });
                return data;
            }
            return new VuexActionResult({ data });
        },
    },
};


export default investigationsStore;
